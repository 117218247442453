<script lang="ts">
  import { onDestroy, setContext } from 'svelte';
  import qs from 'query-string';
  import { writable } from 'svelte/store';
  import Route from './Route.svelte';
  import { bootstrap } from '.';
  import { Context } from 'page';
  import { NavigateOptions, PlatformRouter } from '@/types';

  const router: PlatformRouter = bootstrap();

  let component = writable(null);
  let layout = writable(null);
  let context = writable({} as Context);

  const localNavigate = (opts: NavigateOptions = {}) => {
    const { path, params } = opts;
    const query = qs.stringify(
      {
        ...$context.query,
        ...params,
      },
      { arrayFormat: 'comma' },
    );
    let newPath: string = $context.pathname;
    if (path) {
      if (path.startsWith('/')) {
        newPath = path;
      } else {
        newPath = `${$context.pathname}/${path}`;
      }
    }
    if (query) {
      newPath += '?' + query;
    }
    router.show(newPath);
  };

  setContext('context', context);
  setContext('router', {
    localNavigate,
    navigate: router.show,
  });

  const unsub = router.route.subscribe((v) => {
    if (v.component) {
      component.set(v.component);
    }
    if (v.layout) {
      layout.set(v.layout);
    }
    context.set(v.context || <Context>{});
  });
  onDestroy(unsub);
</script>

<Route component={$component} layout={$layout} context={$context} navigate={router.show} />
